import axios from "axios";
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API+'/qinqin', // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API+process.env.VUE_APP_SAVELOG_BASE_Url, // url = base url + request url
  withCredentials: true,
  crossDomain: true,
  timeout: 50000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    // return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {},
  (error) => {
    // return Promise.reject(error);
  }
);
export default service;
