import request from "../utils/request";
import service from "../utils/journalRequest";
import Qs from "qs";
import { getSessionStorage } from "../utils/date.js";
import store from "@/vuex/store.js"

// 1、 利码联定制 亲亲2022罐装薯片  新项目，还请提供2个赋码域名，项目码为：32010654，三个项目预计赋码量：5000W
// 正式赋码域名：c.qmq00.cn
// 测试赋码域名：1c.s76.top
// 2、 利码联定制 亲亲2022两罐装薯片  新项目，还请提供2个赋码域名，项目码为：32010660
// 正式赋码域名：d.qmq00.cn
// 测试赋码域名：1d.s76.top
// 3、 利码联定制 亲亲2022三罐装薯片  新项目，还请提供2个赋码域名，项目码为：32010661
// 正式赋码域名：e.qmq00.cn
// 测试赋码域名：1e.s76.top

// 获取项目码判断调取哪一个sku的请求地址
// function projectCodeBaseUrl() {
//   let projectCode = store.getters.getProjectCode;
//   return projectCode == 32010660 ? "/qinqin/double" : "/qinqin/three";
// }

export function getTableData (params) {
  return request({
    url: "/history/getHistoryScanRecord",
    method: "post",
    data: params,
  });
}

// 获取微信code
export function getRedirectUrl (params) {
  console.log(params);
  return request({
    url: "/index/getRedirectUrl",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 获取定位参数
export function getLocationParams (params) {
  return request({
    url: "/index/locationParams",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 获取用户信息
export function getUserInfo (params) {
  return request({
    url: "/index/getInfo",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 获取奖品图和转盘信息
export function getImgUrl (params) {
  return request({
    url: "/luckyDraw/queryTurntableImagesAndSweepstakesRules",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 根据经纬度获取位置信息
export function getLocation (params) {
  return request({
    url: "/index/location",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getDrawRecord (params) {
  return request({
    url: "/luckyDraw/queryDrawRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 查询中奖记录
export function getWinningRecord (params) {
  return request({
    url: "/bonus/queryWinningRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 校验项目码
export function checkProjectConfig (params) {
  return request({
    url: "/projectCheck/checkProjectConfig",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 码校验
export function codeCheck (params) {
  return request({
    url: "/projectCheck/codeCheck",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 發起抽獎
export function drawRecord (params) {
  return request({
    url: "/luckyDraw/drawRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}

// 领取红包
export function receiveRedPacket (params) {
  return request({
    url: "/luckyDraw/receiveRedPacket",
    // url: "/luckyDraw/receiveRedPacketByDouMi",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function selectPrizeUrl (params) {
  return request({
    url: "/bonus/selectPrizeUrl",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 埋点请求
export function journal (params) {
  params.fromType = "0";
  params.qrCode = sessionStorage.getItem("qrCode");
  params.projectCode = sessionStorage.getItem("projectCode");
  params.screenLength = document.documentElement.clientHeight;
  params.screenWidth = document.documentElement.clientWidth;
  return service({
    url: "/osa/logAgent/save",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 查询门店绑定信息
export function queryShopInfo (params) {
  return request({
    url: "/shopInfo/queryShopInfo",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 提交门店绑定信息
export function insertShopInfo (params) {
  return request({
    url: "/shopInfo/insertShopInfo",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function qmActivityRule (data) {
  return request({
    url: '/luckyDraw/queryActivityRule',
    method: 'post',
    data
  })
}
