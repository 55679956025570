module.exports = {
  title: "亲亲食品",
  tracking: {
    // url: process.env.VUE_APP_BASE_API + "/qinqin/osa/logAgent/save",
    url: process.env.VUE_APP_BASE_API + process.env.VUE_APP_SAVELOG_BASE_Url+"/osa/logAgent/save",
    scene: {
      //页面进入前
      before: {
        // 路由名称
        activePage: {
          userVisit: 6,
        },
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        activePage: {
          userVisit: 2,
        },
      },
    },
  },
};
